<template>
  <v-card tile>
    <v-app-bar color="primary" dark>
      <v-btn dark icon @click.native="$emit('close-dialog')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer/>
      <v-toolbar-title v-if="!isSearchBoxOpen">
        {{ $vuetify.lang.t('$vuetify.pages.groups.members') }}
      </v-toolbar-title>
      <kurcc-search-box/>
    </v-app-bar>
    <kurcc-staff-list :filter="[`exclude_group_id=${groupId}`]" :selected-ids.sync="selectedIds"
                      :show-select-button="adding"
                      multi-select/>
    <v-btn :loading="loading" :width="'210px'" bottom class="center-x" color="primary"
           elevation="2" fixed @click="addMember">
      {{ $vuetify.lang.t('$vuetify.pages.groups.addMember') }}
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'KurccGroupAddMemberDialogContent',
  props: ['groupId'],
  components: {
    KurccStaffList: () => import('@/modules/app/components/KurccStaffList'),
    KurccSearchBox: () => import('@/modules/app/components/KurccSearchBox')
  },
  data () {
    return {
      selectedIds: [],
      adding: true,
      loading: false
    }
  },
  computed: {
    anyItemsSelected () {
      return this.selectedIds.length > 0
    },
    enteringAddMode () {
      return this.adding === false
    },
    exitingAddMode () {
      return this.adding === true
    },
    isSearchBoxOpen () {
      return this.$store.state.search.isSearchBoxOpen
    }
  },
  methods: {
    addMember () {
      if (this.enteringAddMode) {
        this.adding = true
        return
      }

      if (this.exitingAddMode) {
        if (this.anyItemsSelected) {
          this.loading = true
          this.$store.dispatch('addGroupMember', {
            id: this.groupId,
            member_ids: this.selectedIds
          }).then(() => {
            this.selectedIds = []
            this.adding = false
            this.$emit('close-dialog')
          }).finally(() => {
            this.loading = false
          })
        } else {
          this.adding = false
          this.$emit('close-dialog')
        }
      }
    }
  }
}
</script>
